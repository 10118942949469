@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

html {
  height: fill-available;
  height: -webkit-fill-available;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: fill-available;
  height: -webkit-fill-available;
}


/* Override base SVG style to work with Ant Design if using external icon sets */
svg {
  vertical-align: initial;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(22,119,255, 0.8);
  }
  70% {
      -webkit-box-shadow: 0 0 0 15px rgba(22,119,255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(22,119,255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(22,119,255, 0.8);
    box-shadow: 0 0 0 0 rgba(22,119,255, 0.8);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(22,119,255, 0);
      box-shadow: 0 0 0 15px rgba(22,119,255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(22,119,255, 0);
      box-shadow: 0 0 0 0 rgba(22,119,255, 0);
  }
}